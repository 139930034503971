import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "home" */ '../views/AboutUsView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "home" */ '../views/ContactView.vue')
  },
  {
    path: '/en/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "home" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/en/terms-condition',
    name: 'terms-condition',
    component: () => import(/* webpackChunkName: "home" */ '../views/TermsCondition.vue')
  },
  {
    path: '/uk/privacy-policy',
    name: 'uk-privacy-policy',
    component: () => import(/* webpackChunkName: "home" */ '../views/PrivacyPolicyUK.vue')
  },
  {
    path: '/uk/terms-condition',
    name: 'uk-terms-condition',
    component: () => import(/* webpackChunkName: "home" */ '../views/TermsConditionUK.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
