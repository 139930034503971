import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

// add "resolveJsonModule": true, it in tsconfig.json to solve error
import en from '@/assets/translation/en.json'
import uk from '@/assets/translation/uk.json'

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    messages: {
        en: en,
        uk: uk,
    },
    legacy: false,
    fallbackLocale: 'en'
})

createApp(App).use(i18n).use(router).mount('#app')
